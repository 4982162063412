import React from 'react';
import FormInput from 'ui/components/FormInput';
import { InvestmentsFormColumName } from '../InvestmentsForm.utils';
import { Currencies } from 'kennek/interfaces/loans';

type InvestmentsFromDefaultInvestorInputsProps = {
  visibleInputs: {
    [InvestmentsFormColumName.INVESTOR_RATE]: boolean;
  };
  loanCurrency: Currencies;
  investment: {
    investorName: string;
    investorInvestedAmount: number;
    frequency: string;
    investorCurrentInterest: number;
    lenderOfRecordsName: string;
  };
};
export const InvestmentsFromDefaultInvestorInputs: React.FC<
  InvestmentsFromDefaultInvestorInputsProps
> = ({ loanCurrency, investment, visibleInputs }) => {
  return (
    <div className="flex items-top justify-between mx-3 mt-5 mb-5 border-b border-neutral-300 h-[80px]">
      <div className="flex gap-5">
        <div className="w-[180px] pt-[18px] heading-200">
          {investment.investorName}
        </div>
        <div className="w-[232px] mt-[18px] heading-200">
          {investment.lenderOfRecordsName}
        </div>
        <FormInput
          disabled={true}
          containerClassName="w-[132px] mt-[4px] ml-[8px]"
          currencyInput={loanCurrency}
          value={investment.investorInvestedAmount}
          customLeftPadding="7"
        />
        {visibleInputs[InvestmentsFormColumName.INVESTOR_RATE] && (
          <FormInput
            disabled={true}
            containerClassName="w-[94px] mt-[4px] ml-[8px]"
            leftSymbol="%"
            customLeftPadding="7"
            type="decimalNumber"
            value={investment.investorCurrentInterest}
          />
        )}
        <FormInput
          disabled={true}
          containerClassName="w-[180px] mt-[4px] ml-[8px]"
          value={investment.frequency}
        />
      </div>
    </div>
  );
};
