import { InvestmentsFormFields } from './InvestmentsForm/InvestmentsForm.types';
import { Loan } from 'kennek/interfaces/loans';
import { AmountToFundType } from '@/interfaces/loans';
import { LoanInvestorsMutationPayload } from '@/interfaces/loans/queries';

export const saveInvestmentsPayloadFactory = (params: {
  loanEncodedKey: Loan['encodedKey'];
  investments: InvestmentsFormFields[];
  originatorEncodedKey: LoanInvestorsMutationPayload['originatorEncodedKey'];
  amountToFundType: AmountToFundType;
  fundingMethodId?: string;
  effectiveDate?: string;
  investmentTypeSettingEnabled: boolean;
}): LoanInvestorsMutationPayload => {
  const investmentsPayload: LoanInvestorsMutationPayload['investments'] = (
    params.investments ?? []
  ).map((investment) => ({
    id: investment.id,
    investorId: investment.investorId,
    lenderOfRecordId: investment.lenderOfRecordId,
    amount: investment.amount ? parseFloat(investment.amount) : 0,
    investorInterest: investment.interestRate
      ? parseFloat(investment.interestRate)
      : 0,
    investmentType: params.investmentTypeSettingEnabled
      ? investment.type
      : null,
  }));

  return {
    loanEncodedKey: params.loanEncodedKey,
    investments: investmentsPayload,
    originatorEncodedKey: params.originatorEncodedKey,
    amountToFundType: params.amountToFundType,
    effectiveDate: params.effectiveDate,
    fundingMethodId: params.fundingMethodId,
  };
};
