import React from 'react';
import { cn } from 'ui/utils';
import { AdvancedInvestmentsSettingsForm } from './AdvancedInvestmentsSettingsForm';
import FixedInvestorInterestRateForm from './FixedInvestorInterestForm';

type InvestmentsSettingsFormProps = {
  className?: string;
  advancedSettingsInitiallyExpanded?: boolean;
  fixedInterestRate: {
    disabled: boolean;
    initialFixedInterestRate: number;
    onFixedInterestRateChange: (value: number | null) => void;
  };
  investmentType: {
    enabled: boolean;
    onInvestmentTypeEnabledChange: (value: boolean) => void;
  };
};

export const InvestmentsSettingsForm: React.FC<InvestmentsSettingsFormProps> = (
  props
) => {
  return (
    <div className={cn('bg-neutral-200 rounded-lg', props.className)}>
      <FixedInvestorInterestRateForm
        disabled={props.fixedInterestRate.disabled}
        initialFixedInterestRate={
          props.fixedInterestRate.initialFixedInterestRate
        }
        onFixedInterestRateChange={
          props.fixedInterestRate.onFixedInterestRateChange
        }
      />
      <div className="h-[2px] bg-neutral-400 mx-3" />
      <AdvancedInvestmentsSettingsForm
        initiallyExpanded={props.advancedSettingsInitiallyExpanded ?? false}
        investmentTypeSetting={{
          onInvestmentTypeEnabledChange:
            props.investmentType.onInvestmentTypeEnabledChange,
          enabled: props.investmentType.enabled,
        }}
      />
    </div>
  );
};
