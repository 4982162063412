import { useEffect, useLayoutEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { SvgIcon } from 'kennek/icons';
import { Alert, Button, FormInput, Title } from 'ui';
import { DevTool } from '@hookform/devtools';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import { onAdditionalDetailsSaved } from '@/features/edit-loan/editLoanSlice';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useAppDispatch } from '@/store/hooks';
import {
  onEnterAdditionalDetails,
  onExitAdditionalDetails,
  useSelectDraftLoan,
} from '../onboardingSlice';
import { useCreateDraftLoan, useUpdateDraftLoan } from '../useCreateDraftLoan';
import { ScheduleFormType } from './ScheduleForm';
import { CreateLoan, Loan, LoanProductRules } from '@/interfaces/loans';

export type AdditionalDetailsFormType = {
  gracePeriod: number;
  graceAmount: number;
  penaltyRate: number;
};

export type AdditionalDetailsProps = {
  onContinue: () => void;
  onBack: (arg?: AdditionalDetailsFormType) => void;
  loanProductRules: LoanProductRules;
  isEdit?: boolean;
  loan?: Loan;
  onChangeLoanId?: (loanId: string) => void;
  initialFormValues: AdditionalDetailsFormType;
  getPayload?: (value: { form: AdditionalDetailsFormType }) => CreateLoan;
  formFlow: 'ONBOARDING' | 'REFINANCE';
  setIsSubmittingForm: (val: boolean) => void;
  scheduleForm?: ScheduleFormType;
};

const AdditionalDetailsForm = ({
  onBack,
  onContinue,
  isEdit,
  loan,
  initialFormValues,
  onChangeLoanId,
  loanProductRules,
  getPayload,
  formFlow,
  setIsSubmittingForm,
  scheduleForm,
}: AdditionalDetailsProps) => {
  const dispatch = useAppDispatch();
  const { loanLower } = useGetLabelsConfig();
  const [updateDraftLoan, updateDraftLoanMutation] = useUpdateDraftLoan({
    isEdit,
    scheduleForm,
  });
  const [createDraftLoan, createDraftLoanMutation] = useCreateDraftLoan({
    onChangeLoanId,
    scheduleForm,
    isEdit,
  });
  const isSubmitting =
    updateDraftLoanMutation.isLoading || createDraftLoanMutation.isLoading;
  const error =
    updateDraftLoanMutation.isError || createDraftLoanMutation.isError;

  const draftLoanData = useSelectDraftLoan();

  const { rules, variableRequiredFields } = loanProductRules ?? {};

  const form = useForm<AdditionalDetailsFormType>({
    shouldFocusError: false,
    defaultValues: initialFormValues,
    mode: 'onChange',
  });

  const isDirtyRef = useRef(form.formState.isDirty);

  useLayoutEffect(() => {
    isDirtyRef.current = form.formState.isDirty;
  }, [form.formState.isDirty]);

  useEffect(() => {
    dispatch(onEnterAdditionalDetails());
    return () => {
      dispatch(
        onExitAdditionalDetails({
          form: form.getValues(),
          isDirty: isDirtyRef.current,
        })
      );
    };
  }, [dispatch, form, form.getValues]);

  useEffect(() => {
    setIsSubmittingForm(isSubmitting);
  }, [isSubmitting, setIsSubmittingForm]);

  const onSubmit = form.handleSubmit((loanRepaymentsForm) => {
    const payload = getPayload?.({
      form: loanRepaymentsForm,
    });

    if (formFlow === 'ONBOARDING') {
      if (!isEdit && !draftLoanData) {
        createDraftLoan({ payload, loan }).then(() => onContinue());
      } else {
        updateDraftLoan({ payload, draftLoanData, loan }).then(() =>
          onContinue()
        );
      }
    } else if (formFlow === 'REFINANCE') {
      dispatch(onAdditionalDetailsSaved(loanRepaymentsForm));
      onContinue();
    }
  });

  return (
    <div className="flex flex-col gap-8">
      <Title
        title="Additional details"
        icon={<SvgIcon name="StatementIcon" />}
        titleSize="lg"
        className="mt-2 mb-1"
      />
      <form
        className="form stepper-form"
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        noValidate
      >
        <div className="form-input">
          <FormInput
            label="Days Grace period"
            errors={form.formState.errors?.gracePeriod?.message}
            questionTooltip="The period of time after payment is due, but before late fees, interest, or other penalties start to accrue"
            placeholder="Days"
            type="number"
            defaultValue={
              rules?.gracePeriod.defaultValue !== form.watch('gracePeriod') &&
              rules?.gracePeriod.defaultValue
            }
            defaultValueSetter={() => {
              form.setValue('gracePeriod', rules?.gracePeriod?.defaultValue);
              form.clearErrors('gracePeriod');
            }}
            {...form.register('gracePeriod', {
              valueAsNumber: true,
              // required: LOAN_MESSAGES.REQUIRED_GRACE_PERIOD, // ! Restore after bug LL-3568 fix by mambu
              max: {
                value: rules?.gracePeriod?.maxValue
                  ? rules?.gracePeriod?.maxValue
                  : undefined,
                message: LOAN_MESSAGES.MAX_VALUE_GRACE_PERIOD(
                  rules?.gracePeriod?.maxValue
                ),
              },
              min: {
                value: rules?.gracePeriod?.minValue
                  ? rules?.gracePeriod?.minValue
                  : 0,
                message: LOAN_MESSAGES.MIN_VALUE_GRACE_PERIOD(
                  rules?.gracePeriod?.minValue || 0
                ),
              },
            })}
          />

          <FormInput
            label="Grace Amount %"
            type="number"
            leftSymbol="%"
            questionTooltip={`% of Outstanding Principal above which ${loanLower} is deemed in arrears`}
            placeholder="Percentage amount"
            errors={form.formState.errors?.graceAmount?.message}
            defaultValue={
              rules?.graceAmount.defaultValue !== form.watch('graceAmount') &&
              rules?.graceAmount.defaultValue
            }
            defaultValueSetter={() => {
              form.setValue('graceAmount', rules?.graceAmount?.defaultValue);
              form.clearErrors('graceAmount');
            }}
            {...form.register('graceAmount', {
              valueAsNumber: true,
              // required: LOAN_MESSAGES.REQUIRED_GRACE_AMOUNT, // ! Restore after bug LL-3568 fix by mambu
              max: {
                value: rules?.graceAmount?.maxValue
                  ? rules?.graceAmount?.maxValue
                  : undefined,
                message: LOAN_MESSAGES.MAX_VALUE_GRACE_AMOUNT(
                  rules?.graceAmount?.maxValue
                ),
              },
              min: {
                value: rules?.graceAmount?.minValue
                  ? rules?.graceAmount?.minValue
                  : 0,
                message: LOAN_MESSAGES.MIN_VALUE_GRACE_AMOUNT(
                  rules?.graceAmount?.minValue || 0
                ),
              },
            })}
          />

          {variableRequiredFields?.some(
            (field) => field.name === 'penaltyRate'
          ) && (
            <FormInput
              label="Penalty rate"
              leftSymbol="%"
              type="number"
              errors={form.formState.errors?.penaltyRate?.message}
              defaultValue={
                rules?.penalty?.defaultValue !== form.watch('penaltyRate') &&
                rules?.penalty?.defaultValue
              }
              defaultValueSetter={() => {
                form.setValue('penaltyRate', rules?.penalty?.defaultValue);
                form.clearErrors('penaltyRate');
              }}
              {...form.register('penaltyRate', {
                valueAsNumber: true,
                required: LOAN_MESSAGES.REQUIRED_PENALTY_RATE,
                max: {
                  value: rules?.penalty?.maxValue
                    ? rules?.penalty?.maxValue
                    : undefined,
                  message: LOAN_MESSAGES.MAX_VALUE_PENALTY_RATE(
                    rules?.penalty?.maxValue
                  ),
                },
                min: {
                  value: rules?.penalty?.minValue
                    ? rules?.penalty?.minValue
                    : 0,
                  message: LOAN_MESSAGES.MIN_VALUE_PENALTY_RATE(
                    rules?.penalty?.minValue || 0
                  ),
                },
              })}
            />
          )}
        </div>
        {error && (
          <div className="w-full">
            <Alert type="error" className="w-full">
              <span className="body-400 mt-1">
                One or more tranches have a disbursement date after the final
                payment is due. Review and edit the dates before continuing.
              </span>
            </Alert>
          </div>
        )}

        <div className="flex justify-between">
          <Button
            layout="ghost"
            onClick={() =>
              onBack(formFlow === 'REFINANCE' ? form.getValues() : null)
            }
            disabled={isSubmitting}
          >
            Back
          </Button>

          <Button
            layout="primary"
            onClick={onSubmit}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {formFlow === 'ONBOARDING' ? 'Save and continue' : 'Continue'}
          </Button>
        </div>
      </form>

      <DevTool control={form.control} />
    </div>
  );
};

export { AdditionalDetailsForm };
