import { useState } from 'react';
import { isNotEmpty } from 'kennek/utils/validations';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useAddLoanToGroupMutation,
  useCreateLoanGroupMutation,
  useCreateLoanMutation,
  useUpdateLoanMutation,
  useUploadRepaymentScheduleMutation,
} from '@/services/loans';
import { isFetchBaseQueryError } from '@/services/utils';
import { useAppDispatch } from '@/store/hooks';
import { base64ToFile } from '@/utils/helpers';
import { ScheduleFormType } from './loan-details/ScheduleForm';
import {
  onLoanCreated,
  onLoanGroupCreated,
  onLoanUpdated,
  onSetDraftLoan,
  useSelectGroupedLoanId,
  useSelectLoanInformationForm,
} from './onboardingSlice';
import { Loan } from 'kennek/interfaces/loans';
import { CreateLoan } from '@/interfaces/loans';

export const useCreateDraftLoan = ({
  onChangeLoanId,
  scheduleForm,
  isEdit,
}: {
  onChangeLoanId?: (loanId: string) => void;
  scheduleForm?: ScheduleFormType;
  isEdit: boolean;
}) => {
  const [createLoan, { isLoading: isCreatingLoan }] = useCreateLoanMutation();
  const [createGroup, { isLoading: isCreatingGroup }] =
    useCreateLoanGroupMutation();
  const [addLoanToGroup, { isLoading: isAddingLoanToGroup }] =
    useAddLoanToGroupMutation();
  const [uploadRepaymentSchedule, uploadRepaymentScheduleQuery] =
    useUploadRepaymentSchedule();
  const groupedLoanId = useSelectGroupedLoanId();
  const [error, setError] = useState(false);
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();

  const mutate = async ({
    payload,
    loan,
  }: {
    payload: CreateLoan;
    loan: Loan | null;
  }) => {
    const data = await createLoan(payload).unwrap();
    try {
      dispatch(onSetDraftLoan(data));
      snackbar.show({
        severity: 'primary',
        title: SNACKBAR_ONBOARDING.LOAN_CREATION_TITLE,
      });
      const isScheduleUploaded = await uploadRepaymentSchedule(
        data.id,
        scheduleForm,
        loan,
        isEdit
      );
      if (!isScheduleUploaded) return;
      onChangeLoanId(data.id);

      if (groupedLoanId) {
        const group = await createGroup({
          groupName: data.loanName ?? '',
        }).unwrap();
        await addLoanToGroup({
          loanId: groupedLoanId,
          groupId: group.id,
        }).unwrap();
        await addLoanToGroup({
          loanId: data.id,
          groupId: group.id,
        }).unwrap();
        dispatch(onLoanGroupCreated({ groupId: group.id }));
      }
      dispatch(onLoanCreated());
    } catch (error) {
      // This condition is validating against Mambu. BE should handle our own errors.
      // Should refactor this after BE implements our status codes.
      if (
        isFetchBaseQueryError(error) &&
        error.status === 400 &&
        hasMambuMessage(error.data) &&
        (error.data.message?.[0]?.errorReason ===
          'DISBURSEMENT_DATE_AFTER_LAST_REPAYMENT_DUE_DATE' ||
          error.data.message?.[0]?.errorReason === 'INVALID_DISBURSEMENT_DATE')
      ) {
        setError(true);
      } else {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.LOAN_CREATION_FAILED_TITLE,
        });
      }
    }
  };
  return [
    mutate,
    {
      isLoading:
        isCreatingLoan ||
        isCreatingGroup ||
        isAddingLoanToGroup ||
        uploadRepaymentScheduleQuery.isLoading,
      isError: error,
    },
  ] as const;
};

export const useUpdateDraftLoan = ({
  scheduleForm,
  isEdit,
}: {
  scheduleForm: ScheduleFormType | null;
  isEdit: boolean;
}) => {
  const [updateLoan, updateLoanMutation] = useUpdateLoanMutation();
  const [uploadRepaymentSchedule, uploadRepaymentScheduleQuery] =
    useUploadRepaymentSchedule();
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();
  const [error, setError] = useState(false);

  const mutate = async ({
    payload,
    loan,
    draftLoanData,
  }: {
    payload: CreateLoan;
    loan: Loan;
    draftLoanData: Loan;
  }) => {
    try {
      await updateLoan({
        ...payload,
        id: loan?.encodedKey || draftLoanData?.encodedKey,
      }).unwrap();
      const isScheduleUploaded = await uploadRepaymentSchedule(
        loan?.id,
        scheduleForm,
        loan,
        isEdit
      );
      if (!isScheduleUploaded) return;
      snackbar.show({
        severity: 'primary',
        title: SNACKBAR_ONBOARDING.LOAN_UPDATE_TITLE,
      });
      dispatch(onLoanUpdated());
    } catch (error) {
      // This condition is validating against Mambu. BE should handle our own errors.
      // Should refactor this after BE implements our status codes.
      if (
        isFetchBaseQueryError(error) &&
        error.status === 400 &&
        hasMambuMessage(error.data) &&
        (error.data.message?.[0]?.errorReason ===
          'DISBURSEMENT_DATE_AFTER_LAST_REPAYMENT_DUE_DATE' ||
          error.data.message?.[0]?.errorReason === 'INVALID_DISBURSEMENT_DATE')
      ) {
        setError(true);
      } else {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.LOAN_UPDATE_FAILED_TITLE,
        });
      }
    }
  };

  return [
    mutate,
    {
      isLoading:
        updateLoanMutation.isLoading || uploadRepaymentScheduleQuery.isLoading,
      isError: error,
    },
  ] as const;
};

const useUploadRepaymentSchedule = () => {
  const snackbar = useSnackbar();
  const loanInformationForm = useSelectLoanInformationForm();
  const [uploadRepaymentSchedule, { isLoading: isUploadingSchedule }] =
    useUploadRepaymentScheduleMutation();

  const performRequest = async (
    loanId: string,
    scheduleForm: ScheduleFormType | null,
    loan: Loan | null,
    isEdit: boolean
  ): Promise<boolean> => {
    const uploadedFile = scheduleForm?.importedSchedule;
    if (!uploadedFile) return true;
    try {
      const file = base64ToFile(
        uploadedFile?.base64File,
        uploadedFile.fileName,
        uploadedFile.type
      );
      if (!file) return false;
      const loanAmount = isEdit
        ? loan?.loanAmount
        : loanInformationForm?.loanAmount;
      const balloonPaymentAmount = loanInformationForm?.balloonPaymentAmount;
      const data = new FormData();
      data.append('file', file, uploadedFile.fileName);
      data.append('loanAmount', loanAmount?.toString());
      data.append(
        'repaymentInstallments',
        scheduleForm?.repaymentInstallments?.toString()
      );
      if (isNotEmpty(balloonPaymentAmount)) {
        data.append('balloonPaymentAmount', balloonPaymentAmount.toString());
      }
      data.append('loanId', loanId);

      return uploadRepaymentSchedule(data)
        .unwrap()
        .then(() => {
          return true;
        })
        .catch((e) => {
          const isFeesErr =
            e?.data?.message[0]?.errorReason === 'FEE_NOT_ALLOWED';
          snackbar.show({
            severity: 'error',
            title: isFeesErr
              ? SNACKBAR_ONBOARDING.LOAN_IMPORT_SCHEDULE_FAILED_FEES
              : SNACKBAR_ONBOARDING.LOAN_IMPORT_SCHEDULE_FAILED,
          });
          return false;
        });
    } catch (_e) {
      return false;
    }
  };

  return [performRequest, { isLoading: isUploadingSchedule }] as const;
};

type MambuErrorObject = { errorCode: number; errorReason: string };

const hasMambuMessage = (
  value: unknown
): value is { message: MambuErrorObject[] } => {
  return !!(value as { message: MambuErrorObject[] })?.message?.[0]
    ?.errorReason;
};
