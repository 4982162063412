import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Alert, Button, OnboardingSummaryItem as SummaryItem, Table } from 'ui';
import TitleWithIcon from 'ui/components/TitleWithIcon';
import {
  DocumentTextIcon,
  OfficeBuildingIcon,
  PencilAltIcon,
  UserIcon,
} from '@heroicons/react/outline';
import * as Dialog from '@radix-ui/react-dialog';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import AssetsSummary from '@/components/sections/loanSummary/AssetsSummary';
import FacilitiesSummary from '@/components/sections/loanSummary/FacilitiesSummary';
import TranchesSummaryModal from '@/components/sections/loanSummary/TranchesSummaryModal';
import { USER_INFORMATION_TEXTS } from '@/constants/onboarding';
import { LABELS } from '@/constants/productTypeDictionary';
import { ROUTES } from '@/constants/routes';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import { CustomFees } from '@/enums/custom-fee.enum';
import { getInvestmentTypeLabel } from '@/features/investments/InvestmentsForm/InvestmentsForm.utils';
import CustomInformationTable from '@/features/loan-details/CustomInformationTable';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useLoanDetailsOverwriteLabel } from '@/hooks/useLoanDetailsOverwriteLabel';
import { useGetUserByEmailQuery } from '@/services/accounts';
import { useGetConfigurationProductQuery } from '@/services/kennek';
import {
  useActivateLoanMutation,
  useGetLoanActivationStatusQuery,
  useGetLoanProductRulesQuery,
  useGetLoanQuery,
  useGetLoanSummaryQuery,
} from '@/services/loans';
import { useGetLoanFacilitiesForLoanQuery } from '@/services/loans/facility';
import { useGetInvestmentsQuery } from '@/services/loans/investment';
import {
  useGetAssetsQuery,
  useGetSupportedAssetsQuery,
} from '@/services/loans/securities';
import { useGetCompanyByIdQuery } from '@/services/originator';
import { useGetBorrowerCompanyByIdQuery } from '@/services/originator/borrower-company';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import { useGetSchedulesQuery } from '@/services/scheduling';
import { setSnackBar } from '@/store/alerts';
import { onLoanTableChanged } from '@/store/user';
import { checkDateIsInTheFuture } from '@/utils/dates';
import { zonedDate, zonedNow } from '@/utils/datetime';
import {
  formatAmount,
  formatDate,
  formatPercent,
  pluralizeRepaymentPeriodUnits,
} from '@/utils/formatters';
import {
  getRouterQuery,
  getSingularOrPluralInstallments,
} from '@/utils/helpers';
import { showInvestorName } from '@/utils/onboarding';
import { isNotEmpty } from '@/utils/validation';
import EditDocumentsTable from '../../document-management/EditDocumentsTable';
import { ConfirmFutureLoanDialog } from '../ConfirmFutureLoanDialog';
import { NotifyUsersModal } from '../NotifyUsersModal';
import type { OnboardingSteps } from '../Onboarding';
import { StepperSurface } from '../StepperSurface';
import {
  addFees,
  checkIsFutureDisbursement,
  findFeeInRules,
  getFeeAmountFromRules,
} from '../utils';
import { User } from 'kennek/interfaces/accounts';
import {
  InvestmentType,
  InvestorDetails,
  LenderOfRecord,
} from 'kennek/interfaces/accounts/investor';
import { GetConfigurationProductResponse } from 'kennek/interfaces/kennek/queries';
import { Currencies } from 'kennek/interfaces/loans';
import { Product } from 'kennek/interfaces/products';
import { Column } from 'ui/types';
import {
  IndexInterestRateTypeDto,
  Loan,
  LoanActivationStatus,
  LoanProductRules,
} from '@/interfaces/loans';
import { LoanFacility } from '@/interfaces/loans/facility';
import { LoanInvestment, LoanSummary } from '@/interfaces/loans/queries';
import { BorrowerCompany } from '@/interfaces/originator/borrower-company';
import type { Schedule } from '@/interfaces/schedules';

interface OnBoardingSummaryProps {
  setActiveStep: (step: OnboardingSteps) => void;
  setAreAssetsInSummary: (val: boolean) => void;
  borrower: User;
  borrowerCompany: BorrowerCompany;
  company: BorrowerCompany;
  loan: Loan;
  product: Product;
  reports: Schedule[];
  investments: LoanInvestment[];
  rules: LoanProductRules;
  productType?: string;
  loanActivationStatus?: LoanActivationStatus;
  loanFacilities?: LoanFacility[];
  configurationProductData: GetConfigurationProductResponse;
  loanSummary?: LoanSummary;
  loanSummaryIsLoading: boolean;
  showCustomInformation?: boolean;
  originatorExternalId?: string;
  setDisableLoader: (val: boolean) => void;
  setSummaryPathTitle: Dispatch<SetStateAction<string>>;
}

type ContainerProps = Pick<OnBoardingSummaryProps, 'setActiveStep'> & {
  loanId: string;
  productType?: string;
  setAreAssetsInSummary: (val: boolean) => void;
  borrowerEmail?: string;
  originatorExternalId: string;
  onboardingLoanIsFetching: boolean;
  companyNumber: string;
  showCustomInformation?: boolean;
  setSummaryPathTitle: Dispatch<SetStateAction<string>>;
};

const FeesToExclude = [CustomFees.RepaymentAmountForFactorLoanFee];

const Container = ({
  loanId,
  productType = 'default',
  setAreAssetsInSummary,
  borrowerEmail,
  originatorExternalId,
  onboardingLoanIsFetching,
  companyNumber,
  showCustomInformation,
  ...props
}: ContainerProps) => {
  const [disableLoader, setDisableLoader] = useState(false);
  const { data: loanActivationStatus } = useGetLoanActivationStatusQuery(
    loanId,
    { skip: !loanId }
  );

  const { data: loanSummary, ...loanSummaryQuery } = useGetLoanSummaryQuery(
    { loanId },
    { skip: !loanId }
  );

  const { data: borrower, ...borrowerQuery } = useGetUserByEmailQuery(
    {
      email: borrowerEmail,
      mambuBranchEncodedKey: originatorExternalId,
    },
    { skip: !borrowerEmail || !originatorExternalId }
  );

  const { data: borrowerCompany, ...borrowerCompanyQuery } =
    useGetBorrowerCompanyByIdQuery(
      { id: borrower?.borrowerCompanyIds?.[0] },
      {
        skip: !borrower?.borrowerCompanyIds?.[0],
      }
    );

  const { data: company, ...companyQuery } = useGetCompanyByIdQuery(
    companyNumber || loanSummary?.borrowerCompanyNumber,
    { skip: !companyNumber && !loanSummary?.borrowerCompanyNumber }
  );

  const { data: loan, ...loanQuery } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });

  const { data: product, ...productQuery } = useGetProductsByOriginatorIdQuery(
    { id: originatorExternalId },
    {
      skip: !originatorExternalId || !loan?.productTypeKey,
      // TODO: replace with query by product id when API is available
      selectFromResult: ({ data, currentData, ...res }) => ({
        ...res,
        data: data?.find(
          (product) => product.encodedKey === loan?.productTypeKey
        ),
        currentData: currentData?.find(
          (product) => product.encodedKey === loan?.productTypeKey
        ),
      }),
    }
  );

  const { data: reports, ...reportsQuery } = useGetSchedulesQuery(
    {
      loanId,
    },
    { skip: !loanId }
  );

  const { data: loanInvestments, ...investmentsQuery } = useGetInvestmentsQuery(
    loan?.encodedKey,
    { skip: !loan?.encodedKey }
  );

  const { data: loanProductRules, ...rulesQuery } = useGetLoanProductRulesQuery(
    { id: loan?.productTypeKey },
    { skip: !loan?.productTypeKey }
  );

  const { data: loanFacilities, ...loanFacilitiesQuery } =
    useGetLoanFacilitiesForLoanQuery(loan?.encodedKey, {
      skip: !loan?.encodedKey && !loanProductRules?.facilities,
    });

  const { data: configurationProductData, ...configurationProductQuery } =
    useGetConfigurationProductQuery(
      {
        branchEncodedKey: originatorExternalId,
        productEncodedKey: loan?.productTypeKey,
      },
      {
        skip: !originatorExternalId || !loan?.productTypeKey,
      }
    );

  const isLoading =
    loanSummaryQuery.isFetching ||
    companyQuery.isFetching ||
    loanQuery.isFetching ||
    productQuery.isFetching ||
    reportsQuery.isFetching ||
    investmentsQuery.isFetching ||
    rulesQuery.isFetching ||
    loanFacilitiesQuery.isFetching ||
    configurationProductQuery.isFetching ||
    borrowerCompanyQuery.isFetching ||
    borrowerQuery.isFetching ||
    onboardingLoanIsFetching;

  if (isLoading && !disableLoader) return <LoadingAnimationPlat />;
  return (
    <StepperSurface>
      <OnboardingSummary
        company={company}
        borrowerCompany={borrowerCompany}
        loan={loan}
        product={product}
        setAreAssetsInSummary={setAreAssetsInSummary}
        borrower={borrower}
        reports={reports}
        investments={loanInvestments}
        rules={loanProductRules}
        loanFacilities={loanFacilities}
        productType={productType}
        loanActivationStatus={loanActivationStatus}
        configurationProductData={configurationProductData}
        setDisableLoader={setDisableLoader}
        loanSummary={loanSummary}
        loanSummaryIsLoading={loanSummaryQuery.isLoading}
        showCustomInformation={showCustomInformation}
        originatorExternalId={originatorExternalId}
        {...props}
      />
    </StepperSurface>
  );
};

const OnboardingSummary: React.FC<OnBoardingSummaryProps> = ({
  setActiveStep,
  setAreAssetsInSummary,
  company,
  borrowerCompany,
  borrower,
  loan,
  product,
  reports,
  investments,
  rules,
  productType,
  loanActivationStatus,
  loanFacilities,
  configurationProductData,
  loanSummary,
  setDisableLoader,
  loanSummaryIsLoading,
  setSummaryPathTitle,
  showCustomInformation,
  originatorExternalId,
}) => {
  const [openModal, setOpenModal] = useState<
    'tranches' | 'future-disbursement' | 'notify-users'
  >(null);
  const labelsConfig = useGetLabelsConfig();
  const { loanUpper, loanLower, borrowerLower } = labelsConfig;
  const router = useRouter();

  const dispatch = useDispatch();

  const [showDisbursementDataError, setShowDisbursementDataError] =
    useState<boolean>(false);
  const [showActivateLoanError, setShowActivateLoanError] =
    useState<boolean>(false);

  const [activateLoan, activateLoanMutation] = useActivateLoanMutation();

  const searchParams = useSearchParams();

  const loanId = searchParams.get('loanId');

  const userInformationText = useMemo(() => {
    return USER_INFORMATION_TEXTS(labelsConfig);
  }, [labelsConfig]);

  const handleCompleteOnboarding = (notifyUsers?: {
    [key: string]: boolean;
  }): void => {
    setOpenModal(null);
    setDisableLoader(true);
    activateLoan({
      loanEncodedKey: loan?.encodedKey,
      notifyUsers: Object.keys(notifyUsers).filter((key) => notifyUsers[key]),
    })
      .unwrap()
      .then(() => {
        const isFutureDisbursement = checkIsFutureDisbursement(
          loan?.disbursementDetails?.expectedDisbursementDate,
          product?.type,
          configurationProductData?.otherConfigurations.paymentsInAdvance
        );

        if (isFutureDisbursement) {
          dispatch(
            setSnackBar({
              severity: 'primary',
              title:
                SNACKBAR_ONBOARDING.BORROWER_ONBOARDING_LOAN_SCHEDULED_FOR_DISBURSEMENT,
            })
          );

          dispatch(onLoanTableChanged('set-for-disbursement'));
          router.push(ROUTES.SERVICING_LOANS);
          return;
        }

        dispatch(
          setSnackBar({
            severity: 'primary',
            title:
              SNACKBAR_ONBOARDING.BORROWER_ONBOARDING_COMPLETED(labelsConfig),
          })
        );
        const path = getRouterQuery(ROUTES.LOAN_DETAILS, { loanId });
        router.push(path);
        dispatch(onLoanTableChanged('active-loans'));
      })
      .catch((error) => {
        dispatch(
          setSnackBar({
            severity: 'error',
            title:
              error?.data?.message?.message ||
              SNACKBAR_ONBOARDING.BORROWER_ONBOARDING_FAILED,
          })
        );
        setDisableLoader(false);
      });
  };

  const tableColumns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        maxWidth: 320,
        minWidth: 320,
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        Cell: ({ value }: { value: Schedule['frequency'] }) => (
          <span className="capitalize">
            {value?.replaceAll('_', ' ').toLowerCase()}
          </span>
        ),
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: 'Due Date',
        accessor: 'deadline.days',
        Cell: ({ row: { original } }: { row: { original: Schedule } }) => {
          return `${
            original?.deadline?.days
          } ${original?.deadline?.dayType?.toLowerCase()} days`;
        },
        maxWidth: 180,
        minWidth: 180,
      },
      {
        Header: 'Calculation Date',
        accessor: 'deadline.calculationDate',
        Cell: ({
          value,
        }: {
          value: Schedule['deadline']['calculationDate'];
        }) => {
          return (
            <span className="capitalize">
              {value?.replaceAll('_', ' ').toLowerCase()}
            </span>
          );
        },
        maxWidth: 220,
        minWidth: 220,
      },
      {
        Header: 'Reminders',
        accessor: 'remindDaysBeforeDeadline',
        Cell: ({ cell: { value } }) => <span>{value} days prior</span>,
        maxWidth: 150,
        minWidth: 150,
      },
    ];
  }, []);

  const shouldDisplayInvestorRateColumn = investments.some(
    (investment) => investment.currentInterest
  );

  const investorRateColumnWidth = 150;
  const columnsWidths = {
    withoutInterestRate: {
      investor: 450,
      lender: 325,
    },
    withInterestRate: {
      investor: 450 - investorRateColumnWidth * 0.75,
      lender: 325 - investorRateColumnWidth * 0.25,
    },
  }[
    shouldDisplayInvestorRateColumn ? 'withInterestRate' : 'withoutInterestRate'
  ];

  const tableInvestors = useMemo(() => {
    return [
      {
        Header: 'Investor',
        accessor: 'investor',
        Cell: ({
          row: {
            original: {
              investor: { details },
            },
          },
        }: {
          row: { original: { investor: { details: InvestorDetails } } };
        }) => {
          return <p className="mt-2">{showInvestorName(details)}</p>;
        },
        maxWidth: columnsWidths.investor,
        minWidth: columnsWidths.investor,
      },
      {
        Header: 'Lender Of Record',
        accessor: 'lenderOfRecord',
        Cell: ({
          row: {
            original: { lenderOfRecord },
          },
        }: {
          row: { original: { lenderOfRecord: LenderOfRecord } };
        }) => {
          return lenderOfRecord?.name;
        },
        maxWidth: columnsWidths.lender,
        minWidth: columnsWidths.lender,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({
          row: {
            original: { type },
          },
        }: {
          row: { original: { type: InvestmentType } };
        }) => {
          return type ? getInvestmentTypeLabel(type) : '-';
        },
        maxWidth: 100,
        minWidth: 100,
      },
      shouldDisplayInvestorRateColumn
        ? {
            Header: 'Investor Rate',
            accessor: 'currentInterest',
            id: 'rate',
            Cell: ({
              row: {
                original: { currentInterest },
              },
            }: {
              row: { original: { currentInterest: number } };
            }) => {
              return formatPercent(currentInterest, 2, 2);
            },
            alignRight: true,
            maxWidth: investorRateColumnWidth,
            minWidth: investorRateColumnWidth,
          }
        : null,
      {
        Header: 'Amount Invested',
        accessor: 'currentAmount',
        id: 'amount',
        Cell: ({
          row: {
            original: { currency, currentAmount },
          },
        }: {
          row: { original: { currency: Currencies; currentAmount: number } };
        }) => {
          return formatAmount(currentAmount, true, currency);
        },
        alignRight: true,
        maxWidth: 175,
        minWidth: 175,
      },
    ].filter(Boolean);
  }, []);

  const allTranchesAreInTheFuture =
    loan?.tranches?.length > 0 &&
    !loan?.tranches?.some(
      (tranche) => zonedDate(tranche?.disbursementDate) < zonedNow()
    );

  const handleDraftSave = () => {
    dispatch(onLoanTableChanged('draft-loans'));
    router.push(ROUTES.SERVICING_LOANS);
  };

  const handleDialogClose = () => setOpenModal(null);

  const usersToNotify = useMemo(() => {
    return [
      { id: borrower?._id, email: borrower?.email, role: 'Borrower' },
      ...(investments ?? [])
        .filter((x) => x.investor?.details?.type !== 'GROUP')
        .map(({ investor }) => ({
          email: investor.details.email,
          id: investor._id,
          role: 'Investor',
        })),
    ].filter((user) => !!user.email);
  }, [borrower?._id, borrower?.email, investments]);

  const handleActivateButton = () => {
    const isActivateError = loanActivationStatus?.status === 'BLOCKED';
    setShowActivateLoanError(isActivateError);
    if (isActivateError) return;

    const isFutureDisbursement = checkIsFutureDisbursement(
      loan?.disbursementDetails?.expectedDisbursementDate,
      product?.type,
      configurationProductData?.otherConfigurations.paymentsInAdvance
    );

    if (isFutureDisbursement) {
      setOpenModal('future-disbursement');
      return;
    }

    const disbursementIsInTheFuture = checkDateIsInTheFuture(
      zonedDate(loan?.disbursementDetails?.expectedDisbursementDate)
    );

    if (disbursementIsInTheFuture) {
      setShowDisbursementDataError(true);
      return;
    }

    if (usersToNotify.length > 0) {
      setOpenModal('notify-users');
      return;
    }

    handleCompleteOnboarding({});
  };

  const handleFutureLoanConfirm = async () => {
    handleCompleteOnboarding({});
  };

  const { data: securities, refetch } = useGetAssetsQuery(loan.encodedKey, {
    skip: !loan.encodedKey,
  });

  const { data: supportedAssets } = useGetSupportedAssetsQuery(
    loan.encodedKey,
    {
      skip: !loan.encodedKey,
    }
  );

  useEffect(() => {
    if (securities) setAreAssetsInSummary(true);
  }, [securities, setAreAssetsInSummary]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const activeTab = loanSummary?.isSetForDisbursment
      ? 'set-for-disbursement'
      : 'draft-loans';
    dispatch(onLoanTableChanged(activeTab));
  }, [loanSummary]);

  const isFactorLoan = rules?.factorLoan;
  const isRevenueBasedFactorLoan =
    configurationProductData?.otherConfigurations?.revenueBasedFactorLoan;
  const isLoanAmount = !!loan?.loanAmount;
  const overwriteLabel = useLoanDetailsOverwriteLabel();
  const {
    firstRepaymentDayLabel,
    deductedInterestLabel,
    loanAmountLabel,
    loanAmountTooltip,
  } = useMemo(() => {
    const firstRepaymentDayLabel = overwriteLabel.getPropertyLabel(
      LABELS[productType].firstRepaymentDate,
      'FIRST_REPAYMENT_DATE',
      configurationProductData?.grace
    );
    const deductedInterestLabel = overwriteLabel.getPropertyLabel(
      'Deducted Interest',
      'DEDUCTED_INTEREST_SETUP',
      configurationProductData?.grace
    );
    const loanAmountLabel = overwriteLabel.getPropertyLabel(
      `${loanUpper} Amount`,
      'LOAN_AMOUNT',
      configurationProductData?.grace
    );

    const loanAmountTooltip = overwriteLabel.getPropertyTooltip(
      `Initial ${loanUpper} Amount`,
      'LOAN_AMOUNT'
    );
    return {
      firstRepaymentDayLabel,
      deductedInterestLabel,
      loanAmountLabel,
      loanAmountTooltip,
    };
  }, [configurationProductData?.grace, productType]);

  const companyData = company || borrowerCompany;

  const isFutureDisbursement = checkIsFutureDisbursement(
    loan?.disbursementDetails?.expectedDisbursementDate,
    product?.type,
    configurationProductData?.otherConfigurations.paymentsInAdvance
  );

  // NOTE: Temporarily block users from editing loans with disbursment in the future
  // https://kennek.atlassian.net/browse/LSP-8551
  const editable = !loanSummary?.isSetForDisbursment;

  const counterpartySummaryItemContent = loan.tranches[0]?.counterparty
    ? `${loan.tranches[0]?.counterparty?.bankAccountHolderName} (${loan.tranches[0]?.counterparty?.bankAccountNumber})`
    : loan.counterparty
      ? `${loan.counterparty?.bankAccountHolderName} (${loan.counterparty?.bankAccountNumber})`
      : null;

  useEffect(() => {
    if (loanSummaryIsLoading) return;
    setSummaryPathTitle(
      editable ? `Create new ${borrowerLower}` : `${loanUpper} details`
    );
  }, [editable, loanSummaryIsLoading, setSummaryPathTitle]);

  return (
    <div className="flex flex-col">
      {!editable ? (
        <>
          <span className="heading-400 mb-4 select-none">Summary</span>
          <div className="w-full mb-2">
            <Alert type="info" className="w-full">
              <span className="body-400 font-medium block h-5">
                Set for disbursement
              </span>
              <span className="body-400 mt-1 block">
                {`This ${loanLower} is scheduled to be disbursed to the ${borrowerLower} on ${formatDate(
                  loan?.disbursementDetails?.expectedDisbursementDate
                )}`}
              </span>
            </Alert>
          </div>
        </>
      ) : (
        <>
          <span className="heading-400 mb-4 select-none">
            Summary - Final Step
          </span>
          <span className="mb-4">Review and validate the data</span>
        </>
      )}

      <div
        className={classNames('grid gap-2', {
          'grid-cols-2': !!borrower,
          'grid-cols-1': !borrower,
        })}
      >
        <div className="border border-neutral-300 rounded-md p-4 flex flex-col body-200">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="flex items-start">
                <div className="flex items-center relative">
                  <OfficeBuildingIcon height={20} className="-left-6 top-4" />
                </div>
                <div className="flex flex-col ml-2">
                  <span className="heading-300">{companyData?.name}</span>
                  <span className="body-200">{companyData?.number}</span>
                </div>
              </div>
              {editable && (
                <button onClick={() => setActiveStep('COMPANY')}>
                  <PencilAltIcon height={20} className="text-secondary-500" />
                </button>
              )}
            </div>
            <div className="flex flex-col mt-6 pl-7">
              <span className="heading-200">{companyData?.country}</span>
              <span className="body-200">{companyData?.region}</span>
              <span className="body-200">{companyData?.city}</span>
              <span className="body-200">{companyData?.address}</span>
              <span className="body-200">{companyData?.addressLine1}</span>
              <span className="body-200">{companyData?.addressLine2}</span>
            </div>
          </div>
        </div>
        {borrower && (
          <div className="border border-neutral-300 rounded-md p-4 flex flex-col body-200">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <div className="flex items-start">
                  <TitleWithIcon
                    title={userInformationText.TITLE}
                    icon={<UserIcon />}
                    insideCard
                    titleSize="md"
                  />
                </div>
                {editable && (
                  <button onClick={() => setActiveStep('USER')}>
                    <PencilAltIcon height={20} className="text-secondary-500" />
                  </button>
                )}
              </div>
              {borrower && (
                <div className="flex flex-col mt-6 pl-7">
                  <span className="heading-200">
                    {borrower?.userName?.split(' ')?.[0]}{' '}
                    {borrower?.userName?.split(' ')?.[1]}
                  </span>
                  <span className="body-200">{borrower?.email}</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 gap-2 mt-2">
        <div className="border border-neutral-300 rounded-md p-4 flex flex-col body-200">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="flex items-start">
                <TitleWithIcon
                  title={`${loanUpper} Details`}
                  icon={<SvgIcon name="MoneyIcon" />}
                  insideCard
                  titleSize="md"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 border-t pb-8 border-neutral-300 mt-5">
              <div className="flex flex-col mt-8 pl-7">
                <div className="flex justify-between">
                  <span className="body-200 text-neutral-700">
                    {loanUpper.toUpperCase()} INFORMATION
                  </span>

                  {editable && (
                    <button
                      onClick={() =>
                        setActiveStep('LOAN_DETAILS.LOAN_INFORMATION')
                      }
                    >
                      <PencilAltIcon
                        height={20}
                        className="text-secondary-500"
                      />
                    </button>
                  )}
                </div>

                <SummaryItem content={loan?.loanName} />

                <SummaryItem
                  topic={`${loanUpper} type: `}
                  content={product?.name || loanSummary?.type}
                />

                <SummaryItem
                  topic={`${loanUpper} ID: `}
                  content={loan?.externalId || loan?.id}
                />

                {loan?.netLoanCalculationMethod === 'LTV' && (
                  <>
                    <SummaryItem topic="LTV: " content={`${loan?.ltv} %`} />
                    <SummaryItem
                      topic="GDV: "
                      content={formatAmount(loan?.gdv, true, rules.currency)}
                    />
                  </>
                )}

                {loan?.netLoanCalculationMethod === 'LTC' && (
                  <>
                    <SummaryItem topic="LTC: " content={`${loan?.ltc} %`} />
                    <SummaryItem
                      topic="GDC: "
                      content={formatAmount(loan?.gdc, true, rules.currency)}
                    />
                  </>
                )}

                {isLoanAmount && (
                  <SummaryItem
                    topic={`${loanAmountLabel}: `}
                    content={formatAmount(
                      loan?.loanAmount,
                      true,
                      loan?.currency
                    )}
                    questionTooltip={loanAmountTooltip}
                  />
                )}

                {isFactorLoan && (
                  <SummaryItem
                    topic="Total Repayment Amount: "
                    content={formatAmount(
                      loanSummary?.totalRepaymentAmount,
                      true,
                      loanSummary?.currency
                    )}
                  />
                )}
                {!!loan.feeSettings?.feeAmortizationDetails && (
                  <Fragment key="fee-amortization">
                    <SummaryItem
                      topic={'Effective Interest Rate: '}
                      content={`${loan?.feeSettings?.feeAmortizationDetails?.effectiveInterestRate} %`}
                    />
                    <SummaryItem
                      topic={'Gross Yield (not compounded): '}
                      content={`${loan?.feeSettings?.feeAmortizationDetails?.grossYield} %`}
                    />

                    <SummaryItem
                      topic={'Fee Yield: '}
                      content={`${loan?.feeSettings?.feeAmortizationDetails?.feeYield} %`}
                    />
                  </Fragment>
                )}

                {loan?.disbursementDetails?.fees?.map((fee) => {
                  const feeFound = findFeeInRules(
                    rules,
                    fee.predefinedFeeEncodedKey
                  );
                  return (
                    feeFound &&
                    !FeesToExclude.includes(
                      feeFound?.customId as CustomFees
                    ) && (
                      <SummaryItem
                        topic={`${feeFound?.name}: `}
                        content={formatAmount(
                          fee?.amount ||
                            getFeeAmountFromRules(
                              fee.predefinedFeeEncodedKey,
                              loan?.loanAmount,
                              rules
                            ),
                          true,
                          loan?.currency
                        )}
                        key={fee.predefinedFeeEncodedKey}
                      />
                    )
                  );
                })}

                {loan?.interestSettings?.indexRateType ===
                  IndexInterestRateTypeDto.INDEX && (
                  <SummaryItem
                    topic="Base Index rate: "
                    content={loanSummary?.baseIndexRate}
                  />
                )}

                {!(isFactorLoan || isRevenueBasedFactorLoan) && (
                  <SummaryItem
                    topic={`${
                      loan?.interestSettings?.indexRateType ===
                      IndexInterestRateTypeDto.INDEX
                        ? 'Spread Interest'
                        : 'Interest rate'
                    }: `}
                    content={`${
                      loan?.interestSettings?.interestSpread ??
                      loan?.interestSettings?.interestRate
                    } %`}
                  />
                )}

                {loan?.interestSettings?.indexRateType ===
                  IndexInterestRateTypeDto.INDEX && (
                  <>
                    <SummaryItem
                      topic="Floor:"
                      content={
                        isNotEmpty(loan?.interestSettings?.interestFloor)
                          ? `${loan?.interestSettings?.interestFloor} %`
                          : `-`
                      }
                    />
                    <SummaryItem
                      topic="Ceiling:"
                      content={
                        isNotEmpty(loan?.interestSettings?.interestCeiling)
                          ? `${loan?.interestSettings?.interestCeiling} %`
                          : `-`
                      }
                    />
                  </>
                )}

                {loan?.scheduleSettings?.periodicPaymentAmount > 0 && (
                  <>
                    <SummaryItem
                      topic="Periodic payment amount:"
                      content={
                        isNotEmpty(
                          loan?.scheduleSettings?.periodicPaymentAmount
                        )
                          ? formatAmount(
                              loan?.scheduleSettings?.periodicPaymentAmount,
                              true,
                              loan?.currency
                            )
                          : `-`
                      }
                    />
                    <SummaryItem
                      topic="Balloon payment %:"
                      content={
                        isNotEmpty(
                          loan?.scheduleSettings?.balloonPaymentPercentage
                        )
                          ? `${loan?.scheduleSettings?.balloonPaymentPercentage * 100} %`
                          : `-`
                      }
                    />
                    <SummaryItem
                      topic="Balloon payment amount:"
                      content={
                        isNotEmpty(loan?.scheduleSettings?.balloonPaymentAmount)
                          ? formatAmount(
                              loan?.scheduleSettings?.balloonPaymentAmount,
                              true,
                              loan?.currency
                            )
                          : `-`
                      }
                    />
                  </>
                )}
              </div>

              <div className="flex flex-col mt-8 pl-14">
                <div className="flex justify-between">
                  <span className="body-200 text-neutral-700">SCHEDULE</span>
                  {editable && (
                    <button
                      onClick={() => setActiveStep('LOAN_DETAILS.SCHEDULE')}
                    >
                      <PencilAltIcon
                        height={20}
                        className="text-secondary-500"
                      />
                    </button>
                  )}
                </div>
                {!isRevenueBasedFactorLoan && (
                  <SummaryItem
                    topic={`${LABELS[productType].repaymentInstallments}: `}
                    content={loan?.scheduleSettings?.repaymentInstallments}
                  />
                )}
                {rules?.minimumInterestPeriod && (
                  <SummaryItem
                    topic="Minimum interest period: "
                    content={loan?.minimumInterestPeriod}
                  />
                )}
                {!isRevenueBasedFactorLoan &&
                  loan?.scheduleSettings?.repaymentPeriodCount > 0 &&
                  loan?.scheduleSettings?.repaymentPeriodUnits && (
                    <SummaryItem
                      topic="Repaid every: "
                      content={`${loan.scheduleSettings.repaymentPeriodCount} ${pluralizeRepaymentPeriodUnits(loan.scheduleSettings.repaymentPeriodCount, loan.scheduleSettings.repaymentPeriodUnits)}`}
                    />
                  )}
                {rules?.principalCollectionFrequency > 1 && (
                  <>
                    <SummaryItem
                      topic="Principal collection: "
                      content={rules?.principalCollectionFrequency}
                      unit={getSingularOrPluralInstallments(
                        rules?.principalCollectionFrequency
                      )}
                    />
                    <SummaryItem
                      topic="Payment holiday: "
                      content={loan?.paymentHoliday}
                      unit={getSingularOrPluralInstallments(
                        loan?.paymentHoliday
                      )}
                    />
                  </>
                )}
                {(rules?.rollUpDuration || rules?.rollUpDuration2) && (
                  <SummaryItem
                    topic="Roll-up duration: "
                    content={loan?.rollUpDuration}
                    unit={getSingularOrPluralInstallments(loan?.rollUpDuration)}
                  />
                )}
                {(!!loan?.interestSettings?.interestGraceInstallments ||
                  loan?.interestSettings?.interestGraceInstallments === 0) && (
                  <SummaryItem
                    topic={`${
                      rules.interestGrace
                        ? 'Interest periods retained'
                        : 'Interest grace period'
                    }: `}
                    content={`${
                      loan?.interestSettings?.interestGraceInstallments
                    } ${getSingularOrPluralInstallments(
                      loan?.interestSettings?.interestGraceInstallments
                    )}`}
                  />
                )}
                {loan?.interestSettings?.interestGraceDeducted > 0 && (
                  <SummaryItem
                    topic={`${deductedInterestLabel}: `}
                    content={formatAmount(
                      loan?.interestSettings?.interestGraceDeducted,
                      true,
                      loan?.currency
                    )}
                  />
                )}
                <SummaryItem
                  topic={`${firstRepaymentDayLabel}: `}
                  content={formatDate(loan.firstRepaymentDate)}
                />
                {!!loan?.scheduleSettings?.monthlyRepaymentDay && (
                  <SummaryItem
                    topic={`${LABELS[productType].monthlyRepaymentDay}: `}
                    content={loan?.scheduleSettings?.monthlyRepaymentDay}
                  />
                )}
                {loan?.tranches?.length > 0 ? (
                  <>
                    {}
                    <SummaryItem topic="First disbursement" />

                    <SummaryItem
                      topic="Date: "
                      content={formatDate(
                        loan?.tranches?.[0]?.disbursementDate
                      )}
                    />

                    <div className="flex justify-between">
                      <SummaryItem
                        topic="Amount: "
                        content={formatAmount(
                          addFees(
                            loan?.tranches?.[0]?.fees,
                            loan?.tranches?.[0]?.amount,
                            rules
                          ),
                          true,
                          loan?.currency
                        )}
                      />
                      <button
                        type="button"
                        className="flex gap-2 items-start heading-200 text-secondary-400  stroke-secondary-400"
                        onClick={() => setOpenModal('tranches')}
                      >
                        See all <SvgIcon name="RightIcon" />
                      </button>
                    </div>
                  </>
                ) : (
                  <SummaryItem
                    topic="Disbursement date: "
                    content={formatDate(
                      loan?.disbursementDetails?.previewDisbursementDate ??
                        loan?.disbursementDetails?.expectedDisbursementDate ??
                        loan?.disbursementDetails?.disbursementDate
                    )}
                  />
                )}
                {rules?.exitFeeEnabled && (
                  <SummaryItem
                    topic="Exit fee: "
                    content={formatAmount(
                      loan?.exitFeeAmount,
                      true,
                      loan?.currency
                    )}
                  />
                )}
                {counterpartySummaryItemContent && (
                  <SummaryItem
                    topic="Counterparty account: "
                    content={counterpartySummaryItemContent}
                  />
                )}
              </div>

              {!isRevenueBasedFactorLoan && (
                <div className="flex flex-col mt-8 pl-16">
                  <div className="flex justify-between">
                    <span className="body-200 text-neutral-700">
                      ADDITIONAL INFORMATION
                    </span>
                    {editable && (
                      <button
                        onClick={() =>
                          setActiveStep('LOAN_DETAILS.ADDITIONAL_DETAILS')
                        }
                      >
                        <PencilAltIcon
                          height={20}
                          className="text-secondary-500"
                        />
                      </button>
                    )}
                  </div>

                  <SummaryItem
                    topic="Grace period: "
                    content={`${loan?.gracePeriod || 0} Days`}
                  />

                  <SummaryItem
                    topic="Grace amount percentage: "
                    content={`${loan?.graceAmount || 0} %`}
                  />

                  {loan?.penaltyRate > 0 && (
                    <SummaryItem
                      topic="Penalty rate: "
                      content={`${loan?.penaltyRate} %`}
                    />
                  )}
                </div>
              )}

              {rules?.facilities && (
                <FacilitiesSummary
                  loanFacilities={loanFacilities}
                  editable={editable}
                  currency={loanSummary?.currency}
                  onEdit={() => {
                    setActiveStep('LOAN_DETAILS.LOAN_INFORMATION');
                  }}
                />
              )}

              <AssetsSummary
                assets={securities}
                supportedAssets={supportedAssets}
                editable={editable}
                rules={rules}
                setActiveStep={setActiveStep}
              />
            </div>
          </div>
        </div>
      </div>

      <TranchesSummaryModal
        open={openModal === 'tranches'}
        tranches={loan?.tranches}
        rules={rules}
        editable={editable}
        onClose={handleDialogClose}
        onEdit={() => {
          setActiveStep('LOAN_DETAILS.SCHEDULE');
        }}
      />

      <div className="space-y-2 mt-2">
        <Table.Container variant="compact">
          <Table.Header>
            <Table.Header.Left>
              <Table.Name
                title={`${loanUpper} Reporting`}
                leftIcon={<DocumentTextIcon />}
                style="heading-300"
              />
            </Table.Header.Left>

            <Table.Header.Right>
              {editable && (
                <button onClick={() => setActiveStep('LOAN_REPORTING')}>
                  <PencilAltIcon height={20} className="text-secondary-500" />
                </button>
              )}
            </Table.Header.Right>
          </Table.Header>
          <Table.Body
            columns={tableColumns as Column<object>[]}
            data={
              reports
                ? [...reports].sort((a, b) => (a.name <= b.name ? -1 : 1))
                : []
            }
            emptyTableMessage="No reports available"
            maxHeight
          />
        </Table.Container>

        <Table.Container variant="compact">
          <Table.Header>
            <Table.Header.Left>
              <Table.Name
                title="Upload Documentation"
                leftIcon={<SvgIcon name="DocumentsIcon" />}
                style="heading-300"
              />
            </Table.Header.Left>

            <Table.Header.Right>
              {editable && (
                <button onClick={() => setActiveStep('UPLOAD_DOCUMENTATION')}>
                  <PencilAltIcon height={20} className="text-secondary-500" />
                </button>
              )}
            </Table.Header.Right>
          </Table.Header>

          <div className="mt-6">
            <EditDocumentsTable
              loanId={loanId}
              readOnly
              noPaddingX
              isEditLoan
            />
          </div>
        </Table.Container>

        <Table.Container variant="compact">
          <Table.Header>
            <Table.Header.Left>
              <Table.Name
                title="Investors"
                leftIcon={<SvgIcon name="MoneyIcon" />}
                style="heading-300"
              />
            </Table.Header.Left>

            <Table.Header.Right>
              {editable && (
                <button onClick={() => setActiveStep('INVESTOR')}>
                  <PencilAltIcon height={20} className="text-secondary-500" />
                </button>
              )}
            </Table.Header.Right>
          </Table.Header>

          <Table.Body<LoanInvestment>
            columns={tableInvestors as Column<object>[]}
            data={investments || []}
            emptyTableMessage="No investors added"
            maxHeight
          />
        </Table.Container>
        {showCustomInformation && (
          <CustomInformationTable
            loanId={loanId}
            branchEncodedKey={originatorExternalId}
            setActiveStep={setActiveStep}
          />
        )}
      </div>

      {showActivateLoanError && (
        <div className="w-full mt-[8px]">
          <Alert type="error" className="w-full">
            <span className="body-400 mt-1">
              The {loanLower} cannot be activated until the&nbsp;
              <Link
                passHref
                href={ROUTES.UNDERWRITING_REQUEST.replace(
                  ':executionId',
                  loanActivationStatus?.data?.executionId || '/requests'
                )}
              >
                <a className="underline font-bold">{loanLower} application</a>
              </Link>
              &nbsp;is accepted.
            </span>
          </Alert>
        </div>
      )}

      {showDisbursementDataError && (
        <div className="w-full mt-2">
          <Alert type="error" className="w-full">
            <span className="body-400 mt-1 font-medium">
              The {loanLower} cannot be activated before the disbursement date{' '}
              {formatDate(loan?.disbursementDetails?.expectedDisbursementDate)}
            </span>
          </Alert>
        </div>
      )}

      {isFutureDisbursement && !loanSummary?.isSetForDisbursment && (
        <div className="w-full mt-2">
          <Alert type="info" className="w-full">
            <span className="body-400 mt-1 font-medium">
              {`The disbursement date for this ${loanLower} is in the future: ${formatDate(
                loan?.disbursementDetails?.expectedDisbursementDate
              )}. Once the ${loanLower} has been scheduled, it will automatically become active on the date of disbursement.`}
            </span>
          </Alert>
        </div>
      )}

      {allTranchesAreInTheFuture && (
        <div className="w-full mt-2">
          <Alert type="error" className="w-full">
            <span className="body-400 mt-1">
              Cannot activate {loanLower} with all tranches in the future
            </span>
          </Alert>
        </div>
      )}

      <div className="flex justify-end gap-3 mt-8">
        {loanSummary?.isSetForDisbursment ? (
          <span className="body-400 text-neutral-600">{`This ${loanLower} will become active on the date of disbursement: ${formatDate(
            loan?.disbursementDetails?.expectedDisbursementDate
          )}`}</span>
        ) : (
          <>
            <Button
              layout="ghost"
              onClick={handleDraftSave}
              disabled={activateLoanMutation.isLoading}
            >
              Save Draft
            </Button>

            <Button
              onClick={() => {
                handleActivateButton();
              }}
              disabled={
                activateLoanMutation.isLoading ||
                activateLoanMutation.isSuccess ||
                allTranchesAreInTheFuture
              }
              loading={
                activateLoanMutation.isLoading || activateLoanMutation.isSuccess
              }
            >
              {isFutureDisbursement
                ? `Schedule ${loanLower}`
                : `Activate ${loanUpper}`}
            </Button>
          </>
        )}

        <Dialog.Root open={openModal === 'notify-users'}>
          <Dialog.Portal>
            <Dialog.Overlay
              className={classNames(
                'fixed',
                'inset-0',
                'z-[100]',
                'bg-neutral-900',
                'bg-opacity-60',
                'overflow-y-auto',
                'flex',
                'justify-center',
                'items-center'
              )}
            >
              <Dialog.Content className="min-w-[390px] max-w-[600px] bg-white p-[20px] rounded-md relative space-y-4">
                <NotifyUsersModal
                  onClose={handleDialogClose}
                  onSubmit={handleCompleteOnboarding}
                  usersToNotify={usersToNotify}
                />
              </Dialog.Content>
            </Dialog.Overlay>
          </Dialog.Portal>
        </Dialog.Root>

        <ConfirmFutureLoanDialog
          open={openModal === 'future-disbursement'}
          disbursementDate={loan?.disbursementDetails?.expectedDisbursementDate}
          onClose={handleDialogClose}
          onSaveDraft={handleDraftSave}
          onConfirm={handleFutureLoanConfirm}
        />
      </div>
    </div>
  );
};

export default Container;
